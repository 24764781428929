body {
  font-family: sans-serif;
  overflow-x: hidden;
  margin-bottom: 100px;
  overflow-x: hidden;
  background-color: coral;
}

.img-style {
  border-radius: 1vw;
}

main {
  z-index: 1;
  background-color: lightyellow;
}

html {
  scroll-behavior: smooth;
}

p,
b:not(footer b),
span:not(#header span, footer span, .dot span, .arrow-down, .icon-box),
.icons {
  color: black;
  font-size: 1.4vw;
  margin: 0.5em 0;
}

@keyframes moveDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  100% {
    transform: translateY(50px);
    opacity: 1;
  }
}

footer {
  padding: 1vw 3vw 0.5vw 3vw !important;
  background-color: lightyellow !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

.menu-items {
  align-items: start;
}

h1,
h5,
.bluScuro {
  font-size: 2vw;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h3 {
  font-size: 1.5vw;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#header {
  position: fixed;
  width: 100%;
  color: black;
  line-height: 2.5vw;
  z-index: 2;
  transition: all 0.4s ease;
  height: 6vw;
  padding: 0 6vw;
}

/* Stile quando la finestra è scrollata in alto */
#header:not(.is-fixed) {
  background-color: rgba(255, 255, 255, 0);
  /* Imposta lo sfondo trasparente */
}

/* Stile quando la finestra è scrollata verso il basso */
#header.is-fixed {
  background-color: white;
  /* Imposta lo sfondo bianco */
  height: 4vw;
  /* Modifica l'altezza */
}

.logo {
  width: 3vw;
  transition: filter 0.4s ease;
  /* Aggiungi una transizione per rendere il cambio di filtro più fluido */
}

.background1::before {
  content: '';
  background: linear-gradient(180deg, #0000 0, coral);
  bottom: 0;
  content: '';
  height: 50.5vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.text-logo {
  margin-left: -5px;
  line-height: 1em;
  display: flex;
  flex-direction: column;
  font-size: 1.5vw;
}

.title {
  width: 70%;
  position: absolute;
  left: 15%;
  text-align: center;
  color: white;
  top: 25vw;
}

.white-svg {
  fill: #f4f4f4;
  position: relative;
  top: 110vh;
}

.card-container {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: -10vw;
}

.primoParagrafo {
  left: 15%;
  position: fixed;
  text-align: center;
  top: 28%;
  transform: translateY(-50%);
  width: 70%;
  align-items: center;
}

.primoParagrafo h2 {
  font-size: 4vw;
  font-weight: bold;
  color: black;
}

.primoParagrafo p {
  font-size: 2vw;
  font-weight: 400;
  color: black;
}

.paragrafo.noPadding div:first-child {
  padding: 5%;
}

.paragrafo.sfondoScuro {
  background: coral;
}

.paragrafo.reverse {
  flex-direction: row-reverse;
}

.paragrafo {
  text-align: center;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  padding: 5%;
  color: black;
  align-items: center;
  justify-content: space-around;
  background-color: lightyellow;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.paragrafo h1,
.paragrafo h3 {
  color: rgb(33 32 32);
}

.paragrafo.noPadding {
  padding: 0;
}

.paragrafo > div:not(.card-finali) {
  width: 40%;
}

.paragrafo > img {
  width: 40%;
}

.info-menu {
  font-size: 1vw;
  border-bottom: 1px #ffffff3d solid;
  height: 40%;
}

.info-menu.is-fixed {
  height: 0;
  visibility: collapse;
}

.titolo-card div {
  text-align: center;
}

.titolo-card {
  font-size: 1.5vw;
  width: 100%;
  padding: 1vw 0;
}

.lingua {
  position: absolute;
  float: right;
  right: 6vw;
  top: 50%;
  transform: translateY(0%);
  color: white;
}

.lingua a {
  display: none;
}

.lingua.is-fixed {
  transform: translateY(-50%);
}

.lingua.is-fixed a {
  display: block;
  color: black;
}

.paragrafo.sfondoScuro h1,
.paragrafo.sfondoScuro h3 {
  color: black;
}

.paragrafo h3 {
  margin: 0;
}

.icon-box {
  font-size: 2.5em;
}

/* Stili per dispositivi mobili */
@media screen and (max-width: 768px) {
  h3 {
    font-size: 2vh;
  }

  .paragrafo > div:first-child {
    padding-bottom: 10vh;
  }

  .primoParagrafo {
    width: 70%;
  }

  .menu-items {
    align-items: center;
  }

  .card-finali {
    width: 90%;
  }

  .titolo-card {
    font-size: 2.5vh;
  }

  .paragrafo > img {
    width: 90%;
  }

  .paragrafo.noPadding div:nth-child(2) {
    width: 100%;
  }

  .pallini {
    flex-direction: column;
    height: fit-content;
  }

  p,
  b:not(footer b) {
    font-size: 2vh !important;
  }

  span:not(#header span) {
    font-size: 2vh;
  }

  h1,
  h5,
  .bluScuro {
    font-size: 2.5vh;
  }

  .primoParagrafo h2 {
    font-size: 3vh;
  }

  .primoParagrafo p {
    font-size: 2vh;
  }

  .paragrafo > div:not(.card-finali) {
    width: 90%;
  }

  .analisi {
    height: 80vh;
    background-position-x: 80%;
  }

  .card-container {
    padding: 0 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }

  .card-container div {
    flex: 0 0 100%;
  }

  .white-svg {
    top: 121vh;
  }

  .lingua {
    transform: translateY(-50%);
  }

  #header {
    height: 8vh !important;
    padding: 0.5vh 2vh;
  }

  .info-menu {
    display: none;
  }

  .logo {
    width: 5vh;
    transition: filter 0.4s ease;
  }

  .text-logo {
    font-size: 2.5vh;
  }
}

/* Stili per dispositivi desktop */
@media screen and (min-width: 769px) {
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: coral;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(222, 95, 49);
}

.App {
  text-align: center;
}

.background1 {
  background-image: url(images/sfondo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff00;
  height: 50vh;
  z-index: 1;
  position: relative;
}

.background {
  color: black;
  height: 50vh;
  background-color: lightyellow;
}

/* Animazione del parallasse */
@keyframes parallax {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-50%);
  }
}

.background::after {
  animation: parallax 10s infinite alternate;
  /* Modifica la velocità o la durata a tuo piacimento */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
